<template>
  <v-dialog v-model="active" width="unset">
    <v-card class="pa-2">
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="confirm">{{ confirmText }}</v-btn>
        <v-btn color="default" text @click="closeDialog">{{ cancelText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Confirmation',
    },
    message: {
      type: String,
      default: 'Are you sure? This action cannot be undone.',
    },
    confirmText: {
      type: String,
      default: 'CONFIRM',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
  },
  data() {
    return {
      active: false,
    }
  },
  methods: {
    openDialog() {
      this.active = true
    },
    closeDialog() {
      this.active = false
    },
    confirm() {
      this.$emit('confirmed')
      this.active = false
    },
  },
}
</script>
