<template>
  <v-card class="mb-5">
    <v-dialog v-model="addNewModal" width="80%" max-width="850">
      <AdminCreatePermission :username="username" />
    </v-dialog>

    <ResultMsg ref="resultMsg"></ResultMsg>

    <ConfirmationDialog
      ref="confirmationDialog"
      :message="deleteConfirmText"
      title="Delete Permission"
      confirm-text="DELETE"
      @confirmed="onDeleteConfirmed"
    ></ConfirmationDialog>

    <v-sheet class="d-flex mb-0">
      <v-sheet class="mr-auto">
        <v-card-title> User Permissions </v-card-title>
        <v-card-text> Here is the list of admin permissions for user {{ email }}. </v-card-text>
      </v-sheet>

      <v-sheet class="pa-4">
        <v-btn color="primary" @click="addNewModal = true">
          <v-icon>{{ icons.mdiPlusCircle }}</v-icon>
          &nbsp; NEW PERMISSION
        </v-btn>
      </v-sheet>
    </v-sheet>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="permsList"
      item-key="permissionUuid"
      class="table-rounded"
      show-select
      :items-per-page="30"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 30, 50, 100, -1],
      }"
    >
      <template #[`item.summary`]="{ item }">
        Full access to
        <span v-if="item.organisationUuid"> Workspace "{{ item.organisationName }}" </span>
        <span v-else> entire Organisation "{{ item.groupName }}" </span>
      </template>
      <template #[`item.groupName`]="{ item }">
        <router-link :to="{ name: 'admin-group', params: { groupUuid: item.groupUuid } }">
          {{ item.groupName }}
        </router-link>
      </template>
      <template #[`item.organisationName`]="{ item }">
        <router-link
          v-if="!!item.organisationUuid"
          :to="{ name: 'admin-workspace', params: { organisationUuid: item.organisationUuid } }"
        >
          {{ item.organisationName }}
        </router-link>
        <span v-else> All Workspaces </span>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="mt-1 row-actions">
          <v-btn
            color="error"
            class="mb-1 mr-1 pa-0"
            :loading="item.isDeleting"
            title="Delete Permission"
            @click="clickDeleteItem(item)"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import icons from '@/utils/icons'
import EventBus from '@/classes/EventBus'
import UploadsApi from '@/classes/UploadsApi'
import User from '@/classes/User'
import Groups from '@/classes/Groups'
import Organisations from '@/classes/Organisations'
import Users from '@/classes/Users'
import ConfirmationDialog from '@/views/utils/ConfirmationDialog.vue'
import ResultMsg from '@/views/utils/ResultMsg.vue'
import AdminCreatePermission from '@/views/admin/AdminCreatePermission.vue'

export default {
  components: {
    ConfirmationDialog,
    ResultMsg,
    AdminCreatePermission,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      Groups,
      Organisations,
      User,
      username: '???',
      permsList: [],
      selected: [],
      loading: true,
      headers: [
        { text: 'ORGANISATION', value: 'groupName' },
        { text: 'WORKSPACE', value: 'organisationName' },
        { text: 'SUMMARY', value: 'summary' },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ],
      icons,
      addNewModal: false,
      editModal: false,
      editItem: null,
      deleteConfirmText: null,
    }
  },
  mounted() {
    EventBus.$on('permissionCreated', () => {
      this.loading = true
      this.addNewModal = false
      this.refreshList()
    })
    EventBus.$on('permissionEdited', () => {
      this.loading = true
      this.editModal = false
      this.editItem = null
      this.refreshList()
    })
    Users.onLoaded(() => {
      this.targetUser = Users.getByEmail(this.email)
      this.username = this.targetUser?.sub || '???'
      this.refreshList()
    })
    Organisations.onLoaded(() => {
      this.$forceUpdate()
    })
    Groups.onLoaded(() => {
      this.$forceUpdate()
    })
  },
  methods: {
    async loadUserPerms() {
      const response = await UploadsApi.adminListUserPermissions({ username: this.username })
      if (!response.success) {
        this.$refs.resultMsg.responseMsg(response)
        this.loading = false

        return
      }
      this.permsList = response?.data?.items.map(perm => ({
        ...perm,
        groupName: Groups.getNameById(perm.groupUuid),
        organisationName: Organisations.getNameById(perm.organisationUuid),
      }))
      this.loading = false
    },
    refreshList() {
      this.loading = true
      this.loadUserPerms()
    },
    clickEditItem(permission) {
      this.editItem = permission
      this.editModal = true
    },
    async clickDeleteItem(item) {
      this.editItem = item
      this.deleteConfirmText = `Are you sure you want to delete this permission? This action cannot be undone.`
      this.$refs.confirmationDialog.openDialog()
    },
    async onDeleteConfirmed() {
      this.loading = true
      const item = this.editItem
      const { permissionUuid } = item
      item.isDeleting = true
      this.$forceUpdate()
      const response = await UploadsApi.adminDeletePermission({ permissionUuid })
      item.isDeleting = false
      this.refreshList()
      this.$forceUpdate()
      this.$refs.resultMsg.responseMsg(response)
    },
  },
}
</script>

<style lang="scss" scoped>
.row-actions {
  margin-right: -4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.row-actions .v-btn {
  min-width: 38px;
}
</style>
