<template>
  <v-card class="mb-5">
    <v-card-title> Create a New Permission </v-card-title>
    <v-card-text>Use this form to create a new Permission.</v-card-text>
    <v-card-text>
      <v-alert v-if="errorMsg" type="error">{{ errorMsg }}</v-alert>
      <v-form ref="form" @submit.prevent="onSubmitForm">
        <v-autocomplete
          v-model="groupUuid"
          label="Organisation"
          clearable
          :items="groupList"
          item-value="groupUuid"
          item-text="groupName"
          no-data-text="No results found"
          :rules="groupUuidRules"
          @change="applyOrganisationListFilter"
        ></v-autocomplete>
        <v-autocomplete
          v-model="organisationUuid"
          label="Workspace"
          clearable
          :items="organisationList"
          item-value="organisationUuid"
          item-text="organisationName"
          no-data-text="No results found"
        ></v-autocomplete>
        <v-btn type="submit" color="success" class="mr-2 mt-2" :loading="isSubmitting">
          <v-icon>{{ icons.mdiCheckCircle }}</v-icon>
          &nbsp; Submit
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import icons from '@/utils/icons'
import EventBus from '@/classes/EventBus'
import Groups from '@/classes/Groups'
import Organisations from '@/classes/Organisations'
import UploadsApi from '@/classes/UploadsApi'
import requiredRule from '@/rules/requiredRule'

const orgOptionAll = {
  organisationUuid: '',
  organisationName: 'All Workspaces',
}

export default {
  props: {
    username: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      groupUuid: '',
      groupUuidRules: [requiredRule],
      groupList: [{ groupName: 'Loading...' }],

      organisationUuid: '',
      organisationList: [{ organisationName: 'Loading...' }],

      icons,

      isSubmitting: false,
      Groups,
      Organisations,

      errorMsg: null,
    }
  },
  mounted() {
    Groups.onLoaded(groups => {
      this.groupList = groups.filter(group => !group?.adminWorkspaceOnly)
    })
    Organisations.onLoaded(() => {
      this.applyOrganisationListFilter()
    })
  },
  methods: {
    applyOrganisationListFilter() {
      if (!this.groupUuid) {
        this.organisationList = []

        return
      }
      this.organisationList = [
        orgOptionAll,
        ...Organisations.getAll().filter(organisation => organisation.groupUuid === this.groupUuid),
      ]
    },
    async onSubmitForm() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }

      this.errorMsg = null
      this.isSubmitting = true
      const permission = {
        permissionUuid: uuidv4(),
        username: this.username,
        groupUuid: this.groupUuid,
        organisationUuid: this.organisationUuid || undefined,
      }
      const response = await UploadsApi.adminPersistPermission(permission)
      if (!response?.success) {
        this.errorMsg = response?.message || 'An unexpected error occurred'
      } else {
        // no errors - reset form
        this.errorMsg = null
        this.$refs.form.reset()
        EventBus.$emit('permissionCreated', permission)
      }
      this.isSubmitting = false
    },
  },
}
</script>
