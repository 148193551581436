import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VDialog,{attrs:{"width":"80%","max-width":"850"},model:{value:(_vm.addNewModal),callback:function ($$v) {_vm.addNewModal=$$v},expression:"addNewModal"}},[_c('AdminCreatePermission',{attrs:{"username":_vm.username}})],1),_c('ResultMsg',{ref:"resultMsg"}),_c('ConfirmationDialog',{ref:"confirmationDialog",attrs:{"message":_vm.deleteConfirmText,"title":"Delete Permission","confirm-text":"DELETE"},on:{"confirmed":_vm.onDeleteConfirmed}}),_c(VSheet,{staticClass:"d-flex mb-0"},[_c(VSheet,{staticClass:"mr-auto"},[_c(VCardTitle,[_vm._v(" User Permissions ")]),_c(VCardText,[_vm._v(" Here is the list of admin permissions for user "+_vm._s(_vm.email)+". ")])],1),_c(VSheet,{staticClass:"pa-4"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.addNewModal = true}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlusCircle))]),_vm._v("   NEW PERMISSION ")],1)],1)],1),_c(VDataTable,{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.permsList,"item-key":"permissionUuid","show-select":"","items-per-page":30,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 30, 50, 100, -1],
    }},scopedSlots:_vm._u([{key:"item.summary",fn:function(ref){
    var item = ref.item;
return [_vm._v(" Full access to "),(item.organisationUuid)?_c('span',[_vm._v(" Workspace \""+_vm._s(item.organisationName)+"\" ")]):_c('span',[_vm._v(" entire Organisation \""+_vm._s(item.groupName)+"\" ")])]}},{key:"item.groupName",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'admin-group', params: { groupUuid: item.groupUuid } }}},[_vm._v(" "+_vm._s(item.groupName)+" ")])]}},{key:"item.organisationName",fn:function(ref){
    var item = ref.item;
return [(!!item.organisationUuid)?_c('router-link',{attrs:{"to":{ name: 'admin-workspace', params: { organisationUuid: item.organisationUuid } }}},[_vm._v(" "+_vm._s(item.organisationName)+" ")]):_c('span',[_vm._v(" All Workspaces ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"mt-1 row-actions"},[_c(VBtn,{staticClass:"mb-1 mr-1 pa-0",attrs:{"color":"error","loading":item.isDeleting,"title":"Delete Permission"},on:{"click":function($event){return _vm.clickDeleteItem(item)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }