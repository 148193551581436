import EventBus from '@/classes/EventBus'
import UploadsApi from '@/classes/UploadsApi'
import User from '@/classes/User'
import onUserLoaded from '@/utils/onUserLoaded'

let users = []

const UsersApi = {
  getAll() {
    return users
  },

  async refresh() {
    if (User.isAdmin) {
      const response = await UploadsApi.adminListUsers()
      if (response?.success) {
        users = response?.data?.items
      } else {
        users = []
        alert(`ERROR FETCHING USERS\n\n${response?.message || 'An unexpected error occurred while fetching users'}`)
      }
    } else {
      users = []
    }
    EventBus.$emit('usersRefreshed', users)

    return users
  },

  onLoaded(func) {
    if (users.length) {
      func(users)
    }

    EventBus.$on('usersRefreshed', func)
  },

  getByUsername: username => users.find(user => user.sub === username),
  getByEmail: email => users.find(user => user.email === email),
}

// Event handlers
onUserLoaded(() => {
  UsersApi.refresh()
})
EventBus.$on('userCreated', () => {
  UsersApi.refresh()
})
EventBus.$on('userEdited', () => {
  UsersApi.refresh()
})

export default UsersApi
